/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <!--<div class="statistic-box">
      <de-statistic @selectionChange="statisticBlockSelected" :dateDeb="dateDeb" :dateFin="dateFin"/>
    </div>-->
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des souscriptions</h4>
        </div>
        <!-- <div v-if="$can('create', 'Azoli')" class="btn-group">
          <a
            @click.prevent="
              $router.push({ name: 'espace.entreprise.offre.add' })
            "
            href=""
            class="style_btn btn btn-warning"
            >Ajouter une offre</a
          >
        </div> -->

        <!-- <div class="btn-group ms-4" v-if="$can('validate', 'Azoli')" >
            <a :disable="selected.length == 0" @click.prevent="validerFiche" href="#" class="style_btn btn btn-primary">Valider la fiche</a>
          </div> -->
        <!-- <div class="dropdown">
            <button v-if="$can('transfer', 'FinOc')" class="btn ms-4 style_btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Affectation</button>
            <ul  class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton1" style="margin: 0px;">
              <li v-if="$can('transferToAgent', 'FinOc')" class="border">
                <conseiller-selector size="lg" title="" @saved="transfertDemandeur" ref="conseillerSelector">
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#">Affecter à des conseillers</a>
                  </template>
                </conseiller-selector>
              </li>
              <li v-if="$can('transferToAntenne', 'FinOc')" class="border">
                 <antenne-selector-dialog size="lg" title="" @saved="transfertDemandeur" ref="antenneSelector">
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#">Affecter vers une antenne</a>
                  </template>
                </antenne-selector-dialog>-->
        <!-- <a class="dropdown-item" href="#">Affecter vers une antenne</a> -->
        <!-- </li>
            </ul>
          </div>-->
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <!--<div class="row mb-4" v-if="viewMode=='card'">
            <div class="col-sm-4 col-lg-3 mb-3" v-for="de in mxFilteredItems" :key="de.id">
                <div class="style_profil_card p-3 pt-3"   >
                    <div  @click.stop="showDemandeur(de)"  class="d-flex justify-content-center mb-3" v-if="de.azoli">
                      <azoli-status-badge :de="de" />
                    </div>
                    <div class="mx-auto">
                      <a href="#">
                        <div @click="uploadFile(de)" class="rounded-circle style_bg_img border" :style="`background-image: url(${de.fullPhotoUrl != null? de.fullPhotoUrl : '/assets/img/profilMan.jpg'}); height: 100px; width: 100px;`">
                        </div>
                      </a>
                    </div>
                    <div class="py-3 text-center border-bottom">
                      <div class="d-flex no-wrap justify-content-center align-items-center text-muted">
                         
                      </div>
                      <h6 class="my-1"><a href="#" class="text-dark text-uppercase">{{de.nom}} {{de.prenom}}</a></h6>
                      
                      <small v-else class="text-muted">Réalisé par: <span class="text-bold">LUI-MEME</span> <span class="text-uppercase">, le {{ de.lastLogin | formatDate }}</span></small>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                    
                      <a class="btn btn-info rounded-0 btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Consulter et modifier"  @click.stop="showDemandeur(de)" ><i class="bi bi-info-square"></i></a>
                      <a class="btn btn-danger rounded-0 btn-sm me-1" title="Générer PDF" @click.stop.prevent="downloadFiche(de)"><i class="bi bi-printer"></i></a>
                      <a @click.stop.prevent="remplirFiche(de)" title="Saisir la fiche" class="btn btn-warning rounded-0 btn-sm me-1"><i class="bi bi-file-earmark-check"></i></a>
                      <button title="Valider la fiche me-1" @click.prevent="validerFiche(de)" class="btn btn-light btn-sm">  <i :class="{ 'text-danger' : de.status == 'VALIDE'}" class="bi bi-caret-left-fill"></i></button>
                      <button title="Prise en charge me-1" @click.prevent="priseEnCharge(de)" class="btn btn-primary btn-sm">  <i class="bi bi-file-earmark-medical"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
              <div class="d-flex flex-wrap justify-content-center align-items-center mt-3">
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
        </div>-->

          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(nom)="{ item: offre }">
                  <span class="text-uppercase">{{ offre.nom }}</span>
                </template>
                <template #cell(prenom)="{ item: offre }">
                  <span class="text-uppercase">{{ offre.prenom }}</span>
                </template>
                <template #cell(createdDate)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.createdDate">{{
                    offre.createdDate | dayjs("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(validePar)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.validePar"
                    >{{ offre.validePar.nom }} {{ offre.validePar.prenom }}</span
                  >
                </template>

                <template #cell(actions)="{ item: offre }">
                  <div class="d-flex justify-content-end">
                    <a class="btn btn-success rounded-0 btn-sm me-1" title
                      ><i class="bi bi-pencil-square" @click.stop="update(offre)"></i
                    ></a>
                    <a
                      class="btn btn-info rounded-0 btn-sm me-1"
                      title="Validation d'une offre"
                      @click.stop="update(offre)"
                      ><i class="bi bi-info-square"></i
                    ></a>
                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1" title="Générer PDF" @click.stop.prevent="downloadFiche(offre)"><i class="bi bi-printer"></i></a> -->
                    <!-- <a @click.stop.prevent="remplirFiche(offre)" title="Saisir la fiche" class="btn btn-warning rounded-0 btn-sm me-1"><i class="bi bi-file-earmark-check"></i></a> -->
                    <!-- <button title="Valider la fiche" @click.prevent="validerFiche(offre)" class="btn btn-light btn-sm">  <i :class="{ 'text-danger' : offre.status == 'VALIDE'}" class="bi bi-caret-left-fill"></i></button> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <!--<template v-else>
        <div class="row mb-4" v-if="viewMode=='card'">
          <div class="col-sm-4 col-lg-3 mb-3" v-for="i in mxPagination.rowsPerPage" :key="i">
            <div class="style_profil_card p-3 pt-3"   >
              <div class="custom-skeleton p-3">
                <div class="d-flex flex-column mb-2 align-items-center justify-content-center">
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable :value="[1,2,3,4,5,6,7,8,9,10].map(e => {e})">
            <PColumn v-for="f in tableFields" :key="f.key" :field="f.key" :header="f.label">
              <template #body>
                <PSkeleton ></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>-->
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
// import DeStatistic from '../../../components/espace/demandeur/statistic/DeStatistic.vue'
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
// import AzoliStatusBadge from '../../../components/common/AzoliStatusBadge.vue'
import CAutoComplete from "../../../components/common/CAutoComplete.vue";

// import ConseillerSelector from '../../../components/espace/antenne/transfert/ConseillerSelector.vue'
// import AntenneSelectorDialog from '../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue'
// import * as XLSX from 'xlsx'

import { FILE_UPLOAD_API_URL } from "../../../config/api-config";

export default {
  components: {
    // DeStatistic,
    FicheAzoliReport,
    ReportHeader,
    // ConseillerSelector,
    // AntenneSelectorDialog,
    // AzoliStatusBadge,
    CAutoComplete,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Nom & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    // this.queryStatistics();
    this.fetchCandidatures();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      candidatures: "candidature/candidatures",
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "candidat.nom", label: "Nom" },
        { key: "candidat.prenom", label: "Prénoms" },
        { key: "offre.poste.libelle", label: "Poste" },
        { key: "offre.employeur.raisonSocial", label: "Employeur" },
        { key: "createdDate", label: "Date" },
        { key: "retenu", label: "Retenu" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ];
    },
    parDepartementEnregistresTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parCommuneEnregistresTableFields() {
      return [
        // 'index',
        { key: "commune", label: "Commune" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parAeEnregistresTableFields() {
      return [
        // 'index',
        { key: "ae", label: "AE" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "femmesCount", label: "Nombre femmes" },
        { key: "enfantsCount", label: "Nombre enfants" },
        // { key: 'total', label: 'Total' },
      ];
    },
    parDepartementEnregistresTableData() {
      return this.parDepartementEnregistres;
    },
    parCommuneEnregistresTableData() {
      return this.parCommuneEnregistres;
    },
    parAeEnregistresTableData() {
      return this.parAeEnregistres;
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableData() {
      return this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans;
    },
    tableData() {
      return this.candidatures.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
      fetchCandidatures: "candidature/fetchCandidatures",
    }),
    validation() {},
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) {
        this.$toast.error(
          "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return;
      }
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    // queryStatistics() {
    //   this.mxInitializePaginator({
    //     queryModel: "offre",
    //     search: this.search,
    //     fetcherMethod: "offre/fetchOffres",
    //     dataGetter: "offre/offres",
    //     paginationGetter: "offre/pagination",
    //     autoSubmitQuery: false,
    //     pagination: {
    //       sortBy: "id",
    //       descending: false,
    //       page: 0,
    //       rowsPerPage: 20,
    //       sortingParam: `sort=id,desc`,
    //     },
    //     filterOptions: this.filterOptions,
    //     extraQueryArgsParamBuilder: () => {
    //       const dateDeb = (this.dateDeb || new Date("2021-12-31"))
    //         .toISOString()
    //         .substring(0, 10);
    //       const dateFin = (this.dateFin || new Date("2080-12-31"))
    //         .toISOString()
    //         .substring(0, 10);
    //       return `dateDeb=${dateDeb}&dateFin=${dateFin}`;
    //     },
    //     searchFields: this.searchFields,
    //   });
    // },
    formatToDataTable(data, { keyName }) {
      let summary = {};
      return Object.keys(data).map((k) => {
        let { key, M = 0, F = 0, total = 0 } = data[k];
        summary.M += M;
        summary.F += F;
        summary.total += total;
        if (data[k]["TOTAL"] == undefined) {
          return {
            [keyName]: key,
            M,
            F,
            total,
          };
        } else {
          return {
            [keyName]: "TOTAL",
            ...summary,
          };
        }
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    downloadFiche(de) {
      // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
      // this.downloadAzoli(de).then(()=>{})
      this.fetchDossier({
        demandeurId: de.id,
      }).then(() => {
        console.log(this.$refs.html2Pdf);
        this.$refs.html2Pdf.generatePdf();
      });
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.conseiller.de.edit" });
    },
    showDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({
        name: "espace.conseiller.de.detail",
        params: { demandeurId: demandeur.id },
      });
    },
    update(offre) {
      console.log("avant offre");
      console.log(offre);
      //this.activeDemandeur = { ...demandeur }
      this.$router.push({
        name: "espace.entreprise.offre.edit",
        params: { offreId: offre.id },
      });
    },
    remplirFiche(demandeur) {
      this.$router.push({
        name: "espace.conseiller.azoli.edit",
        params: { demandeurId: demandeur.id },
      });
    },
    validerFiche(demandeur) {
      if (demandeur.status == "VALIDE") {
        this.$toast.info("Fiche déjà validée!", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      if (demandeur.status != "TERMINE") {
        this.$toast.error("Fiche non terminée", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      this.$confirm({
        title: "Confirmation",
        message: `Vous-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`,
        button: {
          yes: "Oui",
          no: "Non",
        },
        callback: (confirm) => {
          if (confirm) {
            this.validerDossier({
              ...demandeur,
              status: "VALIDE",
            }).then((data) => {
              console.log(data);
              this.$toast.success("Fiche validée avec succès!", {
                position: "top-right",
                duration: 10000,
              });
            });
          }
        },
      });
    },
    async destroyDemandeur(demandeur) {
      console.log(demandeur);
      await this.$dialog.confirm({
        size: "md",
        centered: true,
        variant: "success",

        text: "Voulez-vous vraiment supprimer cet élément?",
        actions: {
          false: {
            text: "Non",
            variant: "outline-success",
          },
          true: {
            text: "Oui",
            variant: "success",
            handle: async () => {
              this.deleteDemandeur(demandeur);
            },
          },
        },
      });
      // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
      // if (ok) {
      // }
    },
    updateOrCreateDemandeur(demandeur) {
      console.log(demandeur);
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
